import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';

import _ from 'lodash';
import { FaRegEye } from 'react-icons/fa';
import { usePageViews } from 'src/utils/helper';
dayjs.extend(buddhistEra);

export const Viewer = ({ data = null }) => {
  const _data = _.toNumber(data);
  if (!_.isNumber(_data) || _.isNaN(_data) || _data < 1) return null;
  return (
    <>
      <div className='pageview'>
        <FaRegEye />
        <span> {usePageViews(_.floor(data, 2))} </span>
      </div>
    </>
  );
};

export const SectionHead = ({ data = null, link = null, more = false, moreText = null }) => {
  if (_.isEmpty(data)) return null;
  return (
    <>
      {link ? (
        <>
          {!more ? (
            <div className='section-head'>
              <h2 className='feed-bg'>
                <a
                  href={link || '/#'}
                  //! DATALAYER
                >
                  {data}
                </a>
              </h2>
              <div className='title-pattern'>
                <div className='title-pattern-inner' />
              </div>
            </div>
          ) : (
            <div className='section-head'>
              <h2 className='feed-bg'>
                <a
                  href={link || '/#'}
                  //! DATALAYER
                >
                  {data}
                </a>
              </h2>
              <div className='title-pattern'>
                <div className='title-pattern-inner' />
              </div>
              <div className='more'>
                <a
                  href={link || '/#'}
                  aria-label={data}
                  title={data}
                  //! DATALAYER
                >
                  {moreText || 'more'}
                </a>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='section-head'>
          <div className='feed-bg'> {data} </div>
          <div className='title-pattern'>
            <div className='title-pattern-inner' />
          </div>
        </div>
      )}
    </>
  );
};

export const HeadingLine = ({ data }) => {
  if (_.isEmpty(data)) return null;
  return (
    <div className='headiing-line'>
      <div className='divider --left' />
      <span> {data} </span>
      <div className='divider --right' />
    </div>
  );
};
export const HeadingInContent = ({ data = null, link = null }) => {
  if (_.isEmpty(data)) return null;
  return (
    <div className='heading'>
      <h2>
        <a href={link || '/#'} aria-label={data} title={data}>
          {data}
        </a>
      </h2>
      <p>
        <a href={link || '/#'} aria-label={data} title={data}>
          more
        </a>
      </p>
    </div>
  );
};
